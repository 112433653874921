import React, { useState, useEffect } from "react";
import { CardHeader, Card, CardBody, Row, Col } from "reactstrap";
import { DashVenslider } from "../dashboards/Dashslider";
import axios from "axios";

const HomeLatestVendor = () => {
  const [latestVendor, setLatestVendor] = useState([]);
  const getLatestVendor = async () => {
    try {
      const res = await axios.get(`/publicChannel/latestVendors`);
      if (res) {
        setLatestVendor(res.data.channels);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLatestVendor();
  }, []);

  return (
    <Row>
      <Col lg="12" className="p-0">
        <Card>
          <CardHeader style={{ fontWeight: "600", color: "#32325d" }}>
            Latest Vendors
          </CardHeader>
          <CardBody id="">
            <DashVenslider
              upgrade={"nothingtoupgrade"}
              channels={latestVendor}
              homepage={true}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default HomeLatestVendor;
