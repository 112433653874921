import React, {useState} from "react";
import { Col, Container, Row, Card,CardBody,Button } from "reactstrap";
import "../../../../assets/scss/custom/guestpage.scss"
import { Link } from "react-router-dom";
import { GuestPopup } from 'views/Hoc/Dialog/GlobalDialog';
import img from "../../../../assets/img/LinkedIn.png"
const GuestFooter = () => {

  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null });
  const [openPop, setOpenPop] = useState(false);

  const popUpok = (value) => {
    setOpenPop(false);
  }

  const openDialogs = (id) => {

    if(id == '1'){
      modalPopValue.title = "Legal Information";
      modalPopValue.message = "Legal Information";
      modalPopValue.popUpId = id;
    }
    if(id == '2'){
      modalPopValue.title = "Privacy Policy";
      modalPopValue.message = "Privacy Policy";
      modalPopValue.popUpId = id;
    }
    if(id == '3'){
      modalPopValue.title = "Terms & Conditions";
      modalPopValue.message = "Terms & Conditions";
      modalPopValue.popUpId = id;
    }

    setModalPopValue({...modalPopValue});
    setOpenPop(true);
  }

  const closePopup = () => {
    setOpenPop(false);
  }

  return (
    
     
          <Container fluid className="top-import bg-white pt-4 h-auto" >
            <GuestPopup open={openPop} value={modalPopValue} handleSave={popUpok} handleClose={closePopup} />
    <div className="">
      <div className="text-center text-md-left">
        <Row>
         
          {/* <hr className="clearfix w-100 d-md-none" /> */}
          <Col lg="3" md="3">
          <div className="businet">
            <h3 style={{fontFamily:"Open Sans,sans-serif"}} className=" text-#525f7f">
             ChannelHub
            </h3>
            <ul className="list-unstyled footertext">
              {/* <li className="mt-3">
                <a ><i class="fas fa-angle-double-right mr-2"></i>About</a>
              </li>
              <li className="mt-3 text-small">
                <a ><i class="fas fa-angle-double-right mr-2"></i>BLog</a>
              </li> */}
               <li className="mt-3">
               <a href="https://meetings.hubspot.com/shuza" target="_blank" rel="noopener noreferrer">
    <i className="fas fa-angle-double-right mr-2"></i> Schedule a demo
  </a>
              </li>
              <li className="mt-3">
                <Link to="/HelpSupport"> 

                <a ><i class="fas fa-angle-double-right mr-2"></i>Contact us
               </a></Link>
              </li>
               
              <li className="mt-3">
                <Link to="/Aboutus"> 

                <a ><i class="fas fa-angle-double-right mr-2"></i>About us
               </a></Link>
              </li>
              
              <li className="mt-3">
              <Link to="/subscriber"> 

        <a ><i class="fas fa-angle-double-right mr-2"></i>Subscribe to our newsletter
        </a></Link>
                
              </li>
              {/* <li className="mt-3">
                <a ><i class="fas fa-angle-double-right mr-2"></i>Events</a>
              </li> */}
            </ul>
            </div>
          </Col>
          {/* <hr className="clearfix w-100 d-md-none" /> */}
          <Col lg="3" md="3">
            <div className="businet">
            <h3 style={{fontFamily:"Open Sans,sans-serif"}} className="text-#525f7f">
             Business Network
            </h3>
            <ul className="list-unstyled">
              <li className="mt-3">
             <Link to="/iamvendor"><a ><i class="fas fa-angle-double-right mr-2"></i>I’m a vendor
              </a></Link> </li>


                
{/*               
              <li className="mt-3">
                <Link to="/iambuyer" > 

                <a ><i class="fas fa-angle-double-right mr-2"></i>I’m a buyer
               </a></Link>
              </li> */}


              
              {/* <li className="mt-3">
                <a ><i class="fas fa-angle-double-right mr-2"></i>Events
</a>
              </li> */}
              <li className="mt-3">
              <Link to="/signup" > 
                <a ><i class="fas fa-angle-double-right mr-2"></i>Application form
              </a></Link>
              </li>
              


              <li className="mt-3">
                <Link to="/Login" > 

                <a ><i class="fas fa-angle-double-right mr-2"></i>Login
               </a></Link>
              </li>
              {/* <li className="mt-3">
                <a ><i class="fas fa-angle-double-right mr-2"></i>Apply</a>
              </li> */}
            </ul>
        
            </div>
          </Col>
          <Col  lg="3" md="3">
          <div className="businet">
            <h3 style={{fontFamily:"Open Sans,sans-serif"}} className="  text-#525f7f">
          Legal informations
            </h3>
            <ul className="list-unstyled footertext">
              <li className="mt-3">
                <a style={{cursor: 'pointer'}} onClick={() => openDialogs('1')}><i class="fas fa-angle-double-right mr-2"></i>Legal information</a>
              </li>
              <li className="mt-3">
                <a style={{cursor: 'pointer'}} onClick={() => openDialogs('2')}><i class="fas fa-angle-double-right mr-2"></i>Privacy Policy</a>
              </li>
              <li className="mt-3">
                <a style={{cursor: 'pointer'}} onClick={() => openDialogs('3')}><i class="fas fa-angle-double-right mr-2"></i>Terms & Conditions</a>
              </li>
              
            </ul>
            </div>
            </Col>
          {/* <hr className="clearfix w-100 d-md-none" /> */}
          {/* <Col lg="3" md="3">
          <div className="businet">
             <h3 style={{fontFamily:"Open Sans,sans-serif"}} className="text-#525f7f ">
          Latest News
            </h3>
          <ul className="list-unstyled footertext">
            <li className="mt-3">
            <a className="news-link" href="https://channelhub.net/channelhub-to-host-400-consumer-tech-retailers-from-50-countries-in-first-retail-connect-emea!">ChannelHub to host 400 consumer tech retailers from 50 countries in first Retail Connect EMEA</a>
             </li> 
             <span className="mt-1" style={{color:'#525f7f'}}>August 14, 2020 - 12:24 pm</span>
            
            <li className="mt-3">
            <a className="news-link" title="Marvel Distributes Aqara" href="https://channelhub.net/marvel-distributes-aqara/">Marvel Distributes Aqara</a>
            </li> 
            <span className="news-time mt-1" style={{color:'#525f7f'}}>August 14, 2020 - 12:24 pm</span>
            <li className="mt-3">
            <a className="news-link" href="https://channelhub.net/tp-link-appoints-koyuncu-elektronik/">TP-Link Appoints Koyuncu Elektronik</a></li><span class="news-time mt-1" style={{color:'#525f7f'}}>August 14, 2020 - 12:24 pm</span>
              
              </ul>
              </div >
          </Col> */}
         
      <Col Col lg="3" md="3">
      <div className="p-0 mb-4">
      <h3 >Connect with US</h3></div>
      {/* <hr className="clearfix w-100" /> */}
          <div className="" style={{display:"flex",width:"50%", alignItems:"center", justifyContent:"center"}}>
          

          <Button className="btn-icon-only btn-linkedin" type="button" >
            <img src={img} style={{width:"2.380rem", height:"2.375rem"}} />
          </Button>

                    {/* <Button className="btn-icon-only rounded-circle" color="twitter" type="button">
                        <span className="btn-inner--icon text-white"><i className="fab fa-twitter" /></span>
                    </Button>
                    <Button className="btn-instagram btn-icon-only rounded-circle" color="default" type="button">
                         <span className="btn-inner--icon text-white"> <i className="fab fa-instagram" /></span>
                    </Button>
                    <Button className="btn-icon-only rounded-circle" color="pinterest" type="button">
                      <span className="btn-inner--icon text-white"><i className="fab fa-pinterest" /></span>
                    </Button>
                    <Button className="btn-icon-only rounded-circle mr-4" color="youtube" type="button">
                      <span className="btn-inner--icon text-white"><i className="fab fa-youtube" /></span>
                    </Button>
                */}
                </div> 
                </Col>
                </Row>
                <hr className="clearfix w-100" />
                <h4 className="text-center pb-4 tricoth pt-2" style={{color:"#525f7f"}}>© Copyright 2024 ChannelHub</h4>
      </div>
      </div>
           
   </Container>
  );
}

export default GuestFooter;