import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { CardHeader, Card, CardBody, Row, Col } from "reactstrap";
import NextEventPlane from './NextEventPlane';
const NextEventsSlider = () => {
  const [events, setEvents] = useState([]);

  const getCurrentEvents = async () => {
    try {
      let response = await axios.get(`/publicChannel/nextEvents`)
      const filteredData = response.data.aggregation.filter((item) => {
        return new Date(item.fromDate).getTime() > Date.now();
      }).slice(0, 2);;
    
      setEvents(filteredData);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getCurrentEvents();
  }, []);

  return (
    <Row>
      <Col lg='12' className='p-0'>
        <Card>
          <CardHeader style={{ fontWeight: "600", color: "#32325d" }}>Next Events</CardHeader>
          <CardBody>
         <NextEventPlane events={events}/>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default NextEventsSlider