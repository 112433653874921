import * as React from "react";
import { Grid, Paper } from "@mui/material";
import { useTheme, useMediaQuery } from '@mui/material';

const NextEventPlane = ({ events }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));

  const months = {
    "01": "January",
    "02": "February",
    "03": "March",
    "04": "April",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "August",
    "09": "September",
    "10": "October",
    "11": "November",
    "12": "December",
  };

  const makeDate = (fromdate, todate) => {
    let startDate = fromdate[8] == "0" ? fromdate.substring(9) : fromdate.substring(8);
    let endDate = todate[8] == "0" ? todate.substring(9) : todate.substring(8);
    let year = todate.substring(0, 4);
    let month = months[todate.substring(5, 7)];
    return startDate + " - " + endDate + " " + month + " " + year;
  };

  return (
    <Grid container spacing={2} justifyContent={"space-evenly"}>
      {events.map((x) => (
        <Grid item xs={12} spacing={2} md={4} sx={{margin:"0 0.1px 0 0"}}>
          <Paper
            elevation={3}
            style={{ padding: "4px", height: "100%", borderRadius: "10px", width: isLargeScreen ? "530px" : "auto"}}
          >
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                if (x.eventSiteUrl) {
                  let win = window.open(x.eventSiteUrl);
                  win.focus();
                }
              }}
            >
              <div
                className="list-image"
                style={{
                  width: isLargeScreen ? "480px" : "90%",
                  minHeight:isLargeScreen  ? "295px" : "240px",
                  borderRadius: "10px",
                  overflow: "hidden",
                  marginTop:"20px"
                }}
              >
                <img
                  alt="..."
                  src={
                    x.eventImageList
                      ? x.eventImageList.documentUrl
                      : "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"
                  }
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h3
                  style={{ margin: "0.5rem 0 0 0", maxWidth:"85%" }}
                >{`${x.name} - ${x.country.country}`}</h3>
                <h3 style={{ color: "#2c9db7", margin: "0", maxWidth:"85%"}}>
                  {`${makeDate(x.fromDate, x.toDate)} - ${x.address} `}
                </h3>
                <p style={{ color: "#777", margin: "0 0 8px 0",  maxWidth:"85%" }}>
                  {x.shortDescription}
                </p>
              </div>
            </a>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default NextEventPlane;
