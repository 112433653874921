import React, { useEffect, useState } from 'react';
import { Input, AutoComplete } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Badge, Button } from "reactstrap";
import axios from 'axios';
import { history } from '_helpers/history';
import { Search } from "views/pages/Searchlist/Search-Result";

const renderTitle = (title, count) => {
  return (
    <span style={{ fontSize: "14px" }}>
      {title}


      {/* <span onClick={() => ShowResults(title)} className="float-right fg-blue pointer">
      See All
      </span>
      

      <Badge color="primary" style={{fontSize:"12px"}} className="float-right mr-2 ">{count}</Badge> */}
    </span>
  );
};
const ShowResults = (res) => {
  let id;
  if (res === "Vendor")
    id = 11;
  else if (res === "Distributor")
    id = 12;
  else if (res === "Retailer")
    id = 13;
  else if (res === "Products")
    id = 5;
  else if (res === "Contact")
    id = 6;

  console.log("searchlist:", id);

  //   history.replace('/admin/searchResult/'+id);  
}

const renderItem = (title, count) => {
  return {
    key: count + title,
    value: title,
    label: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          textTransform: 'capitalize'
        }}
      >
        {title}
      </div>
    ),
  };
};
const Channels = ['TELECOM LIFESTYLE FASHION BV', 'PANZERGLASS A/S', 'TELLUR (ABN SYSTEMS INTERNATIONAL SRL)', "THE COOP IDEA LTD", 'CELLY'];
const Products = ['Antibacterial Screenprotector', 'eSmartM2'];
const Vendor = ['PANZERGLASS A/S', 'TELLUR (ABN SYSTEMS INTERNATIONAL SRL)'];
//
const renderOption = (data, title) => {
  let dummyarry = [];
  if (title === 'Vendor') {
    data.map((item, index) => {
      //console.log('render item',renderItem(item,12));
      dummyarry.push(renderItem(item?.companyName.toLowerCase(), index));
    });
  } else if (title === 'Products') {
    data.map((item, index) => {
      //console.log('render item',renderItem(item,12));
      dummyarry.push(renderItem(item.toLowerCase(), index));
    });
  }

  else if (title === 'Distributor') {
    data.map((item, index) => {
      //console.log('render item',renderItem(item,12));
      dummyarry.push(renderItem(item?.companyName.toLowerCase(), index));
    });
  }


  else if (title === 'Retailer') {
    data.map((item, index) => {
      dummyarry.push(renderItem(item?.companyName.toLowerCase(), index));
    });
  }

  return dummyarry;
}
let option = [];
let typeId;


const GuestTopSearch = (props) => {
  const [empty, setEmpty] = useState('');
  const [state, setState] = useState({
    options: [],
    company: [],
    product: [],
    type: '',
  });
  const [open, setOpen] = React.useState(true); 
  const { btype } = props;
  const GuestBuyerNew =  props.GuestBuyerNew; 

  const onSelect = (value, option) => {

    let data = state.company.filter(x => x.companyName.toLowerCase() == value);
    if (data.length > 0) {
      const win = window.open('/channelDetailView/' + data[0].channelId);
      win.focus();
      // history.replace('/channelDetailView/'+data[0].channelId);  
    }
    else {
      // console.log("product:", state.product);
      // let data = state.product.filter(x => x.product.toLowerCase() == value);
      // console.log("product:",data);

      // history.push('/productDetailView/'+data[0].productSKU);  
      // const win = window.open('/productDetailView/' + data[0].productSKU);
      // win.focus();
    }

    //admin/productDetail/SKU000897791
  }

  const handleSearch = async (inputText) => {

    setEmpty(inputText);

    await axios.get('guest/topcompany/' + inputText).then(res => {
      option = [];

      state.company = res.data.company;
      //state.product = res.data.product;
      //   if(res.data.type==2)
      //   {
      //     option.push({
      //       label: renderTitle('Distributor'),
      //       options: renderOption(res.data.company.filter(x => x.channelTypeId==3).slice(0,4),'Distributor'),
      //     },{
      //       label: renderTitle('Retailer'),
      //       options: renderOption(res.data.company.filter(x => x.channelTypeId==4).slice(0,4),'Retailer'),
      //     });
      //   }
      //   else if(res.data.type==3)
      //   {
      option.push({
        label: renderTitle('Vendor', res.data.company.filter(x => x.channelTypeId == 2).length),
        options: renderOption(res.data.company.filter(x => x.channelTypeId == 2), 'Vendor'),
      }, {
        label: renderTitle('Distributor', res.data.company.filter(x => x.channelTypeId == 3).length),
        options: renderOption(res.data.company.filter(x => x.channelTypeId == 3), 'Distributor'),
      }, {
        label: renderTitle('Retailer', res.data.company.filter(x => x.channelTypeId == 4).length),
        options: renderOption(res.data.company.filter(x => x.channelTypeId == 4), 'Retailer'),
      },
        {
          // label: renderTitle('Reseller', res.data.company.filter(x => x.channelTypeId == 5).length),
          // options: renderOption(res.data.company.filter(x => x.channelTypeId == 5).slice(0, 4), 'Reseller'),
          label: renderTitle('Reseller', res.data.company.filter(x => x.channelTypeId == 5).length),
          options: renderOption(res.data.company.filter(x => x.channelTypeId == 5), 'Reseller'),
        },
        // {
        //   label: renderTitle('Products', res.data.product.length),
        //   options: renderOption(res.data.product.map(x => x.product), 'Products'),
        // }
        );
      //   }
      //   else if(res.data.type==4)
      //   {
      //     option.push({
      //       label: renderTitle('Vendor'),
      //       options: renderOption(res.data.company.filter(x => x.channelTypeId==2).slice(0,4),'Vendor'),
      //     },{
      //       label: renderTitle('Distributor'),
      //       options: renderOption(res.data.company.filter(x => x.channelTypeId==3).slice(0,4),'Distributor'),
      //     },{
      //       label: renderTitle('Products'),
      //       options: renderOption(res.data.product.map(x => x.product),'Products'),
      //     });
      //   }      

    });
    state.options = option;
    setState({ ...state, options: state.options })

  }
  const [Width, setWidth] = useState(300)
  const Seacrhfocus = () => {
    // console.log('focus')
    //   document.getElementById('advanced').style.left="600px"
  }

  const Seacrhblur = () => {
    // document.getElementById('advanced').style.left="250px"
    //setWidth(300)
  }

  // useEffect(() => {

  //   (async() => {

  //     await axios.get('channel/topcompany/ced').then(res =>{                           
  //       option=[];
  //       typeId=res.data.type;  
  //       //console.log("intype:",res.data.type,typeId);
  //    if(res.data.type==2)
  //     {
  //       option.push({
  //         label: renderTitle('Distributor'),
  //         options: [],
  //       },{
  //         label: renderTitle('Retailer'),
  //         options: [],
  //       },{
  //         label: renderTitle('Products'),
  //         options: [],
  //       });
  //     }
  //     else if(res.data.type==3)
  //     {
  //       option.push({
  //         label: renderTitle('Vendor'),
  //         options: [],
  //       },{
  //         label: renderTitle('Distributor'),
  //         options: [],
  //       },{
  //         label: renderTitle('Retailer'),
  //         options: [],
  //       },{
  //         label: renderTitle('Products'),
  //         options: [],
  //       });
  //     }
  //     else if(res.data.type==4)
  //     {
  //       option.push({
  //         label: renderTitle('Vendor'),
  //         options: [],
  //       },{
  //         label: renderTitle('Retailer'),
  //         options: [],
  //       },{
  //         label: renderTitle('Products'),
  //         options: [],
  //       });
  //     }
  //     });

  //     setState({...state,options:option,type:typeId})   

  //   })();

  //   //renderOption('ty');
  //   //console.log('options',options)
  // }, [])


  return (
    <div className='g-top'>
      
      <div style={{display:GuestBuyerNew == true ? "none":"block", marginBottom:"10px", fontWeight:"600", fontSize:"14px"}}>ChannelHub is the ultimate solution to connect CE & IT brands with technology channels acros mutliple geographies in Europe Middle East and Africa Region.ChannelHub 
offer the perfect blend of in-person events based on one-on-one meetings& networking and online tools with a matching making platform and online events.
      </div>
      <div className="TopSearch">
        <AutoComplete
          dropdownClassName="certain-category-search-dropdown"
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          dropdownMatchSelectWidth={850}
          style={{
            width: 300,
          }}
          options={empty == '' ? [] : state.options}
          onSearch={handleSearch}
          onSelect={onSelect}

        >

          <div>

            <Input.Search onBlur={Seacrhblur}
              onFocus={Seacrhfocus} className=" d-inline-block" size="large" placeholder={GuestBuyerNew == true ? "Search for brands" : "Search Company" }/>

          </div>

        </AutoComplete>

      </div>
    </div>
  )
};

export default GuestTopSearch;